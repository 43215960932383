







import { Vue, Component } from 'vue-property-decorator';
import { FrontE } from '@/sdk/common/SysConst';
@Component
export default class InsBuilding extends Vue {
    private img:string = '';
    private background:string = '';
    private paddingTop:string = '';
    beforeCreate () {
      if ((!FrontE.pcBuilding && !this.$store.state.isMobile) || (!FrontE.mobileBuilding && this.$store.state.isMobile)) {
        this.$router.push('/');
      }
    }
    created () {
      if ((this.isMobile && !FrontE.mobileBuilding) || (!this.isMobile && !FrontE.pcBuilding)) return;
      this.background = this.isMobile ? '/static/building/Mobile.jpg' : '/static/building/PC.jpg';
      this.img = this.isMobile ? '/static/building/MobileLogo.png' : '/static/building/PCLogo.png';

      this.$HiddenLayer();
    }
    mounted () {
    }
    data () {
      return {
      };
    }

    get isMobile () {
      return this.$store.state.isMobile;
    }

    get indexLayout () {
      return '';
    }
}
